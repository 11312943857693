@import url(https://fonts.googleapis.com/css?family=Oxygen);
body {
  margin: 0;
  font-family: Oxygen;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  color: white;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

a:link {
    text-decoration: none;
}

.logo-home {
    height: 60px;
}

.nav-bar{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    min-height: 10vh;
    background: transparent;
    width: 100%;
    position: fixed;

    transition-timing-function: ease-in;
    transition: 0.5s;
}

.nav-bar.active {
    min-height: 6vh;
    background-color: white;
}

nav.nav-bar li {
    font-size: 20px;
    color: white;
}

nav.nav-bar.active li {
    font-size: 20px;
    color: black;
}

nav.nav-bar li:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    text-decoration: underline;
}

.nav-links{
    width: 30%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    list-style: none;
}

@media only screen and (max-width: 600px) {
    .nav-links{
        width: 75%;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-align-items: center;
                align-items: center;
        list-style: none;
    }
}
.page-1{
    text-align: center;
    height: 100vh;
    background-image: url(/static/media/welcome.2500c290.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 50%;
}
  
#startHeader {
    padding-top: 25vh;
    font-weight: bold;
    font-size: 65px;
    color: white;
}

#startSummary {
    font-size: 40px;
}
#page-2{
    background-color: #191919;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.about-me-header {
    max-height: 50px;
    font-size: 40px;
    padding: 25px 0px 50px 0px;
}

.container-upper {
    max-width: 1000px;
    width: 75%;
    padding: 25px 15px 50px 15px;
}

.container-lower {
    max-width: 1000px;
    width: 75%;
    padding: 25px 15px 75px 15px;
}

.text-container {
    font-size: 20px;
}
#page-3{
    text-align: center;
    height: 45vh;
    background-color: white;
}

.project-header {
    font-size: 40px;
    color: black;
    padding: 15px;
}

.centered {
    margin: auto;
    width: 85vw;
}
  
.horizontal-menu {
    margin: auto;
    width: 100%;
    padding: 10px;
}

.project-item-container {
    background-color: lightcyan;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 25vh;
    height: 25vh;
    margin-left: 25px; margin-right: 25px;
    border-radius: 10px;
    border: solid 1px #eaeaea;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
}

.project-item-content {
    position: absolute;
    bottom: 0;
    width: inherit;
    height: 70%;

    color: black;
    text-align: center;
    font-size: 2vh;
}

.project-language {
    position: absolute;
    width: inherit;
    bottom: 0;
    height: 50%;

    color: black;
    text-align: center;
    font-size: 1.5vh;
}

.project-github-logo {
    position: absolute;
    bottom: 0;
    width: 10vh;
}

.menu-item {
    color: black;
    padding: 0 8px;
    margin: 5px 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    border: none;
}

.arrow-prev, .arrow-next {
    font-size: 7.5vh;
    color: black;
    padding: 20px;
    cursor: pointer;
}
.cv-page {
    display: -webkit-flex;
    display: flex;
    background-color: #191919;
}

.cv {
    padding-left: 100px;
    width: 60%;
    color: black;
    background-color: white;
    margin: 0 auto;
}

.home-logo {
    top: 0;
    float: right;
    padding: 25px 25px;
    height: 60px;
}

.top-container {
    margin-top: 5vh;
}

.image {
    margin-top: 15px;
    float: left;
    border-radius: 50%;
    width: 175px;
}

.header-text {
    width: 800px;
    text-indent: 25px;
    color: black;
    font-size: 45px;
    text-decoration: underline;
}

.introduction {
    padding: 25px 0px 0px 200px;
    width: 75%;
    color: black;
    font-size: 25px;
}

.lower-header {
    font-size: 40px;
    text-decoration: underline;
}

.profile-container {
    padding-top: 50px;
}

.profile-list {
    font-size: 25px;
    padding-top: 25px;
    margin-left: 50px;
}

.profile-list li {
    padding: 10px;
}

.education-container {
    padding-top: 25px;
}

.education-text {
    padding-top: 25px;
    margin-left: 25px;
    font-size: 30px;
}

.skills-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 25px;
}

.skills-top-container {
    padding-left: 25px;
    -webkit-flex: 0 0 85%;
            flex: 0 0 85%;
    width: 100%;
}

.skills-header {
    padding-top: 15px;
    font-size: 30px;
}

.left-side {
    float: left;
    width: 50%;
}

.left-side li {
    padding-top: 10px;
    font-size: 20px;
    margin-left: 50px;
}

.right-side {
    width: 50%;
    float: right;
}

.right-side li {
    padding-top: 10px;
    font-size: 20px;
    margin-left: 50px;
}

.skills-bottom-container {
    padding-left: 25px;
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    width: 100%;
}

.skills-header-right {
    padding-top: 30px;
    font-size: 30px;
}

.work-container {
    padding-top: 25px;
    margin-bottom: 100px;
}

.work-experience-container {
    padding-left: 25px;
}

.work-header {
    padding-top: 25px;
    font-size: 30px;
}

.work-experience-container li {
    font-size: 20px;
    margin-left: 50px;
    padding-top: 10px;
}

@media print {
    .education-container {
        page-break-after: always;
    }

    .cv {
        padding-left: 50px;
        width: 100%;
        color: black;
        background-color: white;
        margin: 0 auto;
    }

    .top-container {
        margin-top: 3vh;
    }

    .education-text {
        padding-top: 50px;
        margin-left: 25px;
        font-size: 30px;
    }

    .home-logo {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .cv-page {
        display: block;
        background-color: white;
        text-align: center;
    }
    
    .cv {
        padding-left: 0px;
        width: 100vw;
        color: black;
        background-color: white;
    }
    
    .home-logo {
        position: absolute;
        left: 0;
        top: 0;
        padding: 15px 0 0 15px;
        float: none;
        height: 50px;
    }
    
    .top-container {
        margin-top: 10vh;
    }
    
    .image {
        float: none;
        margin: auto;
        border-radius: 50%;
        width: 175px;
    }
    
    .header-text {
        width: 100%;
        text-indent: 0;
        color: black;
        font-size: 35px;
        text-decoration: underline;
        padding-top: 30px;
    }
    
    .introduction {
        padding: 15px 0 0 0;
        width: 85%;
        margin: auto;
        color: black;
        font-size: 20px;
    }
    
    .lower-header {
        font-size: 30px;
    }
    
    .profile-container {
        padding-top: 25px;
    }
    
    .profile-list {
        font-size: 20px;
        padding-top: 25px;
        margin-left: 0px;
        list-style-type: none;
    }
    
    .education-text {
        padding-top: 25px;
        margin-left: 0px;
        font-size: 20px;
    }
    
    .skills-container {
        display: block;
        padding-top: 25px;
        list-style-type: none;
    }
    
    .skills-top-container {
        padding-left: 0px;
        -webkit-flex: 0 0;
                flex: 0 0;
        width: 100%;
    }
    
    .skills-header {
        padding-top: 15px;
        font-size: 25px;
    }
    
    .left-side {
        float: none;
        width: 100%;
    }
    
    .left-side li {
        padding-top: 10px;
        font-size: 20px;
        margin-left: 0px;
    }
    
    .right-side {
        width: 100%;
        float: none;
    }
    
    .right-side li {
        padding-top: 10px;
        font-size: 20px;
        margin-left: 0px;
    }
    
    .skills-bottom-container {
        padding-left: 0px;
        -webkit-flex: 0 0;
                flex: 0 0;
        width: 100%;
    }
    
    .skills-header-right {
        padding-top: 30px;
        font-size: 25px;
    }
    
    .work-experience-container {
        padding-left: 0;
    }
    
    .work-header {
        padding-top: 25px;
        font-size: 25px;
    }
    
    .work-experience-container li {
        font-size: 20px;
        margin-left: 50px;
        padding-top: 10px;
        text-align: left;
    }
}
#footer {
    text-align: center;
    background-color: #191919;
    min-height: 20vh;
    -webkit-justify-content: center;
            justify-content: center;
}

.contact-header {
    font-size: 30px;
    padding-top: 40px;
}

.email {
    font-size: 20px;
    padding-top: 20px;
}

.github {
    padding-top: 30px;
    margin-right: 25px;
    color: white;
}

.linkedin {
    padding-top: 30px;
    margin-left: 25px;
    color: white;
}
