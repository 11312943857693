.page-1{
    text-align: center;
    height: 100vh;
    background-image: url(../../images/welcome.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 50%;
}
  
#startHeader {
    padding-top: 25vh;
    font-weight: bold;
    font-size: 65px;
    color: white;
}

#startSummary {
    font-size: 40px;
}