a:link {
    text-decoration: none;
}

.logo-home {
    height: 60px;
}

.nav-bar{
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 10vh;
    background: transparent;
    width: 100%;
    position: fixed;

    transition-timing-function: ease-in;
    transition: 0.5s;
}

.nav-bar.active {
    min-height: 6vh;
    background-color: white;
}

nav.nav-bar li {
    font-size: 20px;
    color: white;
}

nav.nav-bar.active li {
    font-size: 20px;
    color: black;
}

nav.nav-bar li:hover {
    transform: scale(1.2);
    text-decoration: underline;
}

.nav-links{
    width: 30%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}

@media only screen and (max-width: 600px) {
    .nav-links{
        width: 75%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        list-style: none;
    }
}