#footer {
    text-align: center;
    background-color: #191919;
    min-height: 20vh;
    justify-content: center;
}

.contact-header {
    font-size: 30px;
    padding-top: 40px;
}

.email {
    font-size: 20px;
    padding-top: 20px;
}

.github {
    padding-top: 30px;
    margin-right: 25px;
    color: white;
}

.linkedin {
    padding-top: 30px;
    margin-left: 25px;
    color: white;
}