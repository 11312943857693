#page-2{
    background-color: #191919;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-me-header {
    max-height: 50px;
    font-size: 40px;
    padding: 25px 0px 50px 0px;
}

.container-upper {
    max-width: 1000px;
    width: 75%;
    padding: 25px 15px 50px 15px;
}

.container-lower {
    max-width: 1000px;
    width: 75%;
    padding: 25px 15px 75px 15px;
}

.text-container {
    font-size: 20px;
}