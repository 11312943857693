#page-3{
    text-align: center;
    height: 45vh;
    background-color: white;
}

.project-header {
    font-size: 40px;
    color: black;
    padding: 15px;
}

.centered {
    margin: auto;
    width: 85vw;
}
  
.horizontal-menu {
    margin: auto;
    width: 100%;
    padding: 10px;
}

.project-item-container {
    background-color: lightcyan;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vh;
    height: 25vh;
    margin-left: 25px; margin-right: 25px;
    border-radius: 10px;
    border: solid 1px #eaeaea;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 15%);
}

.project-item-content {
    position: absolute;
    bottom: 0;
    width: inherit;
    height: 70%;

    color: black;
    text-align: center;
    font-size: 2vh;
}

.project-language {
    position: absolute;
    width: inherit;
    bottom: 0;
    height: 50%;

    color: black;
    text-align: center;
    font-size: 1.5vh;
}

.project-github-logo {
    position: absolute;
    bottom: 0;
    width: 10vh;
}

.menu-item {
    color: black;
    padding: 0 8px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
}

.arrow-prev, .arrow-next {
    font-size: 7.5vh;
    color: black;
    padding: 20px;
    cursor: pointer;
}